/** @jsx jsx */
import { Box, Grid, jsx, Text } from "theme-ui";
//import React from "react";
import { graphql, Link } from "gatsby";
import Layout from "../../components/Layout";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import {
  FaArrowCircleLeft,
  FaCalendarAlt,
  FaMapMarkerAlt,
  FaTicketAlt,
  FaFileDownload,
} from "react-icons/fa";
import ReactPlayer from "react-player/lazy";
import ShareIcons from "../../components/ShareIcons";
import SEO from "../../components/Seo";

export const pageQuery = graphql`
  query NewsPageQuery($slug: String!) {
    graphCmsNewsItem(slug: { eq: $slug }) {
      date
      slug
      title
      body {
        html
        raw
      }
      featuredImage {
        alt
        gatsbyImageData(
          layout: FULL_WIDTH
          placeholder: DOMINANT_COLOR
          aspectRatio: 1.97
        )
      }
    }
    site {
      siteMetadata {
        siteUrl
      }
    }
  }
`;

const NewsItemPage = ({ data, location }) => {
  //grab banner image
  const bannerImage = getImage(data.graphCmsNewsItem.featuredImage);
  //format date
  let news_date = new Date(data.graphCmsNewsItem.date);
  let news_date_long = news_date;
  const dateTimeFormat = new Intl.DateTimeFormat("en", {
    day: "2-digit",
    month: "short",
  });
  const dateTimeFormatLong = new Intl.DateTimeFormat("en", {
    day: "2-digit",
    month: "long",
    year: "numeric",
  });
  news_date = dateTimeFormat.format(news_date);
  news_date_long = dateTimeFormatLong.format(news_date_long);

  return (
    <Layout>
      <SEO title={`${data.graphCmsNewsItem.title} | News`} />
      <Box className="banner" sx={bannerStyles}>
        <GatsbyImage image={bannerImage} alt="" objectPosition="top" />
        <Box className="bannerTitle">
          <Box>
            <h2>News</h2>
            <h3>{news_date_long}</h3>
          </Box>
        </Box>
      </Box>
      <Box sx={mainStyles}>
        <Box className="body">
          <h1>{data.graphCmsNewsItem.title}</h1>
          <h3>{news_date_long}</h3>
          <div
            className="bodyText"
            dangerouslySetInnerHTML={{
              __html: data.graphCmsNewsItem.body.html,
            }}
          />
          {/* {data.graphCmsNewsItem.videoUrl && (
            <Box className="videoPlayer">
              <ReactPlayer
                url={data.graphCmsNewsItem.videoUrl}
                width="100%"
                height="100%"
              />
            </Box>
          )} */}

          <Box className="bodyButtons">
            <Link to="/news">
              <FaArrowCircleLeft alt="" />
              <span>Back to News List</span>
            </Link>
          </Box>
        </Box>
      </Box>
    </Layout>
  );
};

export default NewsItemPage;

const mainStyles = {
  mx: [3, 4],
  "& .body": {
    pr: [0, 3],
    mb: 4,
    fontSize: 3,
    h1: {
      lineHeight: ["1.15", "normal"],
      mb: [0, 4],
    },
    h3: {
      //hide when not mobile
      display: ["inline-block", "none"],
      p: 2,
      color: "white",
      border: "1px solid white",
      borderLeft: "none",
      bg: "house4",
      fontSize: 3,
      mb: [2, 3],
    },
    "& .bodyText": {
      mb: 4,
      a: {
        color: "house4",
        //px: 2,
        "&:hover": {
          bg: "house4",
          color: "white",
          textDecoration: "none",
        },
      },
    },
    "& .videoPlayer": {
      mb: 4,
      position: "relative",
      paddingTop: "56.25%" /* Player ratio: 100 / (1280 / 720) */,
      "& > div": {
        position: "absolute",
        top: "0",
        left: "0",
      },
    },
    "& .bodyButtons": {
      a: {
        color: "black",
        textDecoration: "none",
        position: "relative",
        transition: "all 0.3s ease-in-out",
        svg: {
          color: "house4",
          mr: 2,
          fontSize: 4,
          position: "absolute",
          marginTop: "2px",
        },
        span: {
          marginLeft: "25px",
          color: "black",
          px: 2,
          transition: "all 0.3s ease-in-out",
        },
        "&:hover, &:focus": {
          span: {
            bg: "house4",
            color: "white",
          },
        },
      },
    },
  },
};

const bannerStyles = {
  "&.banner": {
    display: "grid",
    "& .gatsby-image-wrapper": {
      gridArea: "1/1",
    },
    "& .bannerTitle": {
      gridArea: "1/1",
      position: "relative",
      display: "grid",
      div: {
        position: "absolute",
        bottom: 0,
        left: 4,
        display: "flex",
        h2: {
          p: 2,
          color: "white",
          border: "1px solid white",
          bg: "rgba(0,0,0,0.4)",
          fontSize: 3,
          my: 3,
        },
        h3: {
          display: ["none", "inline"],
          p: 2,
          color: "white",
          border: "1px solid white",
          borderLeft: "none",
          bg: "house4",
          fontSize: 3,
          my: 3,
        },
      },
    },
  },
};
